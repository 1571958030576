body {
    margin: 0;
    font-family: monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*background:radial-gradient(ellipse at center, rgba(255,254,234,1) 0%, rgba(255,254,244,1) 95%, #B7E8EB 100%);
    overflow: hidden;*/
}

.navbar-links {
    color: black;
    font-size: 1.25rem;
    font-family:   source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
    font-weight: 700;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menuContainer {
    border-bottom: lightgray 2px solid;
    padding-bottom: 1em;
}

.siteContainer {
    margin-top: 1.0em;

    padding-top: 1em;
    font-size: 1.25em;
    font-family: monospace;
    color: #000000;
}

.alertText {
    font-size: 1.5em;
    margin: 1rem;
    padding: 0.75rem;
}

.eventPeriod {
    font-size: larger;
    /*font-weight: bold;*/
    margin-bottom: 1em;
}

.eventTalk {
    margin: 1em 0;
    font-size: larger;

}

.artistImage {
    border: 1px solid darkgrey;
    padding: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 100%;
    filter: grayscale(0);
}

.artistImageText {
    font-size: x-small;
    float: right;
}

.artistName {
    font-size: larger;
   /* font-weight: bold;*/
    padding-bottom: 0.5em;
}

.artistBio {
    font-size: 1.1rem;
}

.additionalInfos {
    margin-top: 0.75rem;
    margin-bottom: 1rem;
}

.externalLink {
    text-decoration: none;
    color: black;
}

.table-cell {
    vertical-align: top;
    padding-right: 8px;
    padding-top: 8px;
}

.headline {
    font-size: larger;
    /*font-weight: bold;*/
}

.infoText {
    margin-bottom: 1em;
}

.pswp-gallery {
    margin-top: 3em;
}

.pswp-thumbnail {
    display: inline-block;
    border: 1px solid darkgrey;
    padding: 2px;
    margin-right: 10px;
    margin-bottom: 10px;
    transition: all 200ms;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    filter: grayscale(100%);
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
}

.pswp-thumbnail:hover {
    transform: scale(1.05, 1.05);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    filter: grayscale(0%);
}

.imageCaption {
    font-size: medium;
    color: white;
    background-color: rgba(200, 200, 200, 0.2);
    padding: 1em;
    border-radius: 10px;
}

.fadeIn {
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


.ocean {
    height: 1%;
    position: fixed;
    bottom: -20px;
    left: 0;
    right: 0;
    background: #015871;
    filter: grayscale(00%);
    opacity: 20%;
    z-index: -1;
}

.wave {
    background: url("http://localhost:3000/wave.svg") repeat-x;
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 198px;
    animation: wave 6s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    opacity: 60%;
}

.wave:nth-of-type(2) {
    top: -175px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
    opacity: 80%;
}

@keyframes wave {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -1600px;
    }
}

@keyframes swell {
    0%, 100% {
        transform: translate3d(0, -25px, 0);
    }
    50% {
        transform: translate3d(0, 5px, 0);
    }
}